<template>
	<div>
		<CustomTable
			ref="result"
			id_table="horse_actes_result"
		   	:items="resultats"
		   	:busy.sync="table_busy"
			primaryKey="result_id"
			:buttonActionEventOnly="true"
			:externSlotColumns="['notes']"
		>
			<template v-slot:[`custom-slot-cell(notes)`]="{ data }">
				<div 
					v-if="data.notes && data.notes.length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"
				>
					<font-awesome-icon :icon="['fas', 'eye']" />
				</div>
			</template>
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";
	import Shutter from "@/mixins/Shutter.js"
	import ShutterSanitaire from "@/mixins/shutters-manager/Sanitaire.js"
	import ShutterActs from '@/mixins/shutters-manager/Acts.js'
	import ShutterNote from '@/mixins/shutters-manager/Notes.js'
	import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"

	export default {
		name: 'SanitaireHorse',
		props: ['horse_id'],
		mixins: [Navigation, Shutter, ShutterSanitaire, ShutterActs, ShutterNote, ResultatAnalyse],
		data () {
			return {
				events_tab: {
                    'TableAction::goToEditResult': this.openEditResult,
                    'TableAction::acteAjout': this.openAddActe,
                    'TableAction::goToAddResultNote': this.openAddNote,
                    'SanitaireHorse::refresh': this.loadResultat
                },
				resultats: [],
				table_busy: false,
			}
		},

		mounted() {
			this.loadResultat()
		},

		methods: {
			openEditResult(results) {
				if(!results.every((val, i, arr) => val.result_type === arr[0].result_type )) {
					this.failureToast("toast.result_type_unvalid")
					return false
				}
				this.setupSanitaireResult(results, () => {
					this.shutterPanel().close('sanitaire-result')
					this.loadResultat()
					this.$refs.result.unselectAll()
				})
			},

			openAddActe() {
				this.setupActAdd(this.horse_id.toString(), 'TEST')
			},

			openAddNote(results) {
				const results_ids = results.map(res => res.result_id)
				this.openHorseResultNoteAddForm(parseInt(this.horse_id), results_ids)
			},

			async loadResultat() {
				this.table_busy = true
				this.resultats = await this.getSanitaireResultByHorse(this.horse_id)
				this.table_busy = false
			},

			onShuttersSubmit() {
				this.shutterPanel().close('act-add-form')
				this.shutterPanel().close('note-add-form')
				this.loadResultat()
				this.$refs.result.unselectAll()
			},

			catchColumnNotesShutter(act) {
				this.handleNotePreview(act.notes)
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>